import {ref, watch, computed} from '@vue/composition-api'
import store from '@/store'
import {title} from '@core/utils/filter'

// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useHostCourseRequestsList() {
    // Use toast
    const toast = useToast()

    const refRequestsListTable = ref(null)

    // Table Handlers
    const tableColumns = [
        {
            key: 'name',
            label: 'Full Name',
            sortable: false
        },
        {
            key: 'mobile',
            label: 'Mobile Number',
            sortable: false
        },
        {
            key: 'about_course',
            label: 'About Course',
            sortable: false
        },
        {key: 'email', sortable: false},
        {key: 'country', sortable: false},
        {key: 'speciality', sortable: false},
    ]
    const perPage = ref(10)
    const totalRequests = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const statusFilter = ref(null)
    const dataMeta = computed(() => {
        const localItemsCount = refRequestsListTable.value ? refRequestsListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalRequests.value,
        }
    })

    const reFetchData = () => {
        refRequestsListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, statusFilter], () => {
        reFetchData()
    })

    const fetchRequests = (ctx, callback) => {
        store
            .dispatch('app-host-course-requests/fetchRequests', {
                searchKey: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
                is_active: statusFilter.value,
            })
            .then(response => {
                const {hostCourseRequests, total} = response.data

                callback(hostCourseRequests)
                totalRequests.value = total
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error fetching host course requests list',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }

    return {
      fetchRequests,
        reFetchData,
        tableColumns,
        perPage,
        currentPage,
        totalRequests,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refRequestsListTable,
        statusFilter,
    }
}
